import React, { useState, useEffect } from 'react';

function Company() {
  const [companies, setCompanies] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${apiUrl}/companies/`)
      .then(response => response.json())
      .then(data => setCompanies(data));
  }, [apiUrl]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Companies</h1>
      <ul>
        {companies.map(company => (
          <li key={company.id} className="mb-2 p-2 border rounded">
            {company.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Company;
