import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">API Endpoints</h1>
      <ul>
        <li className="mb-2 p-2 border rounded">
          <Link to="/companies" className="text-blue-500">Companies</Link>
        </li>
        <li className="mb-2 p-2 border rounded">
          <Link to="/departments" className="text-blue-500">Departments</Link>
        </li>
        <li className="mb-2 p-2 border rounded">
          <Link to="/employees" className="text-blue-500">Employees</Link>
        </li>
      </ul>
    </div>
  );
}

export default Home;
