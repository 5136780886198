import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Company from './components/Company';
import Department from './components/Department';
import EmployeeList from './components/EmployeeList';
import Employee from './components/Employee';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Home from './components/Home';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navigation />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/companies" element={<Company />} />
            <Route path="/departments" element={<Department />} />
            <Route path="/employees" element={<EmployeeList />} />
            <Route path="/employees/:id" element={<Employee />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
