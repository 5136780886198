import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function EmployeeList() {
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10); // Number of items per page
  const [totalPages, setTotalPages] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Fetch departments
    fetch(`${apiUrl}/departments/`)
      .then(response => response.json())
      .then(data => setDepartments(data));

    // Fetch employees with pagination
    fetch(`${apiUrl}/paginated_employees/?page=${page}&page_size=${pageSize}`)
      .then(response => response.json())
      .then(data => {
        setEmployees(data.results);
        setTotalPages(Math.ceil(data.count / pageSize));
      });
  }, [page, pageSize, apiUrl]);

  const getDepartmentName = (departmentId) => {
    const department = departments.find(department => department.id === departmentId);
    return department ? department.name : 'Unknown';
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Employees</h1>
      <ul>
        {employees.map(employee => (
          <li key={employee.employee_id} className="mb-2 p-2 border rounded">
            <Link to={`/employees/${employee.employee_id}`} className="text-blue-500">
              {employee.employee_id}: {getDepartmentName(employee.department)} - {employee.age} years old
            </Link>
          </li>
        ))}
      </ul>
      <div className="flex justify-center mt-4">
        <button
          className="px-4 py-2 mx-1 bg-gray-200 rounded"
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        >
          Previous
        </button>
        <span className="px-4 py-2 mx-1">{page} of {totalPages}</span>
        <button
          className="px-4 py-2 mx-1 bg-gray-200 rounded"
          disabled={page === totalPages}
          onClick={() => setPage(page + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default EmployeeList;
