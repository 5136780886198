import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function Employee() {
  const { id } = useParams();
  const [employee, setEmployee] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${apiUrl}/employees/${id}/`)
      .then(response => response.json())
      .then(data => setEmployee(data));
  }, [id, apiUrl]);

  if (!employee) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Employee Details</h1>
      <div className="mb-2 p-4 border rounded">
        <p><strong>ID:</strong> {employee.employee_id}</p>
        <p><strong>Department:</strong> {employee.department.name}</p>
        <p><strong>Age:</strong> {employee.age}</p>
        <p><strong>Years in Company:</strong> {employee.years_in_the_company}</p>
        <p><strong>Salary:</strong> {employee.salary}</p>
        <p><strong>Annual Bonus:</strong> {employee.annual_bonus}</p>
        <p><strong>Full Time:</strong> {employee.full_time ? 'Yes' : 'No'}</p>
        <p><strong>Part Time:</strong> {employee.part_time ? 'Yes' : 'No'}</p>
        <p><strong>Contractor:</strong> {employee.contractor ? 'Yes' : 'No'}</p>
      </div>
    </div>
  );
}

export default Employee;
