import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between">
        <div className="text-white font-bold">
          <Link to="/" className="text-xl">Company Management</Link>
        </div>
        <div className="flex space-x-4">
          <Link to="/" className="text-gray-300 hover:text-white">Home</Link>
          <Link to="/companies" className="text-gray-300 hover:text-white">Companies</Link>
          <Link to="/departments" className="text-gray-300 hover:text-white">Departments</Link>
          <Link to="/employees" className="text-gray-300 hover:text-white">Employees</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
