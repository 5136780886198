import React, { useState, useEffect } from 'react';

function Department() {
  const [departments, setDepartments] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchDepartments = fetch(`${apiUrl}/departments/`).then(response => response.json());
    const fetchCompanies = fetch(`${apiUrl}/companies/`).then(response => response.json());

    Promise.all([fetchDepartments, fetchCompanies])
      .then(([departmentsData, companiesData]) => {
        setDepartments(departmentsData);
        setCompanies(companiesData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [apiUrl]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!departments.length) {
    return <div>No departments available.</div>;
  }

  const getCompanyName = (companyId) => {
    const company = companies.find(company => company.id === companyId);
    return company ? company.name : 'Unknown';
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Departments</h1>
      <ul>
        {departments.map(department => (
          <li key={department.id} className="mb-2 p-2 border rounded">
            {department.name} - {getCompanyName(department.company)}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Department;
